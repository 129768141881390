import axios, { type AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import loading from '@/store/loading';
import auth from '@/store/auth';
import { getAuth } from './auth.api';
import utils from '@/utils';
import { useJWT } from '#auth/composables/useJWT';

const apiClient = axios.create({
  baseURL: `${import.meta.env.VITE_APP_URL}/api`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosRetry(apiClient, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 1000,
});

const { getToken, setToken } = useJWT();

//
const refreshAuthLogic = (failedRequest: any) =>
  getAuth(auth.state.queryParams).then((tokenRefreshResponse) => {
    auth.state.authentication = tokenRefreshResponse.data;

    setToken(tokenRefreshResponse.data.auth_token);
    failedRequest.response.config.headers['Authorization'] = getToken();
    return Promise.resolve();
  });

const authInterceptor = (config: AxiosRequestConfig) => {
  config.headers['Authorization'] = getToken();
  return config;
};

const atlassianUserInterceptor = (config: AxiosRequestConfig) => {
  config.headers['X-Atlassian-User'] = `${auth.state.atlassianId}`;
  config.headers['X-Atlassian-Username'] = `${encodeURIComponent(auth.state.atlassianUsername)}`;
  return config;
};

createAuthRefreshInterceptor(apiClient, refreshAuthLogic);

apiClient.interceptors.request.use(authInterceptor);
apiClient.interceptors.request.use(atlassianUserInterceptor);

const errorInterceptor = (error: AxiosError) => {
  loading.methods.finishLoading();
  // check if it's a server error
  if (!error.response) {
    return Promise.reject(error);
  }

  let errorMessage = '';
  switch (error.response.status) {
    case 400:
    case 401:
      errorMessage = error.response.data.message;
      break;
    case 404:
      return Promise.reject(error);

    default:
      errorMessage =
        'We are having some trouble reaching our servers. Please reach out to support@assetmanagementforjira.com for help';
  }
  if ('notShowErrorMsg' in error.config.headers && error.config.headers['notShowErrorMsg'] === true) {
    return Promise.reject(error);
  }
  loading.methods.setError(true, errorMessage);
  return Promise.reject(error);
};

// request interceptor
apiClient.interceptors.request.use(
  (config) => {
    if ('isBlocking' in config.headers && config.headers['isBlocking'] === true) {
      loading.methods.startLoading();
    }
    return config;
  },
  (error) => {
    loading.methods.finishLoading();
    return Promise.reject(error);
  }
);

// Interceptor for responses
const responseInterceptor = (response: AxiosResponse) => {
  if ('isBlocking' in response.config.headers && response.config.headers['isBlocking'] === true) {
    loading.methods.finishLoading();
  }
  // switch (response.status) {
  //   case 200:
  //     // yay!
  //     console.log("Finished request successfully");
  //     break;
  //   // any other cases
  //   default:
  //   // default case
  // }

  return response;
};

apiClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default apiClient;
